<template>
  <div>
    <div
      class="limits-line"
      v-if="seanceInscriptionLimits && seanceInscriptionLimits.limits && seanceInscriptionLimits.limits.length"
    >
      <div v-for="limit of seanceInscriptionLimits.limits" :key="limit.id">
        <div
          class="sub-limits-line select-line"
          :class="isSelected(limit, null) ? 'selected-line' : ''"
          @click="selectLimit(limit, null)"
        >
          <b-row v-if="hasLimit(limit)">
            <b-col cols="8" class="limit-name">
              <span class="badge badge-dark" v-b-tooltip="getBadgeTooltip(limit)">
                {{ getBadge(limit, null) }}
              </span>
              {{ getName(limit, null) }}
            </b-col>
            <b-col class="text-right">
              <span v-for="moment of limitMoments(limit)" :key="moment">
                <div
                  class="limit-value"
                  :style="getStyle(limit, moment, null)"
                  v-b-tooltip="getTooltip(limit, moment, null)"
                >
                  {{ getText(limit, moment, null) }}
                </div>
              </span>
            </b-col>
          </b-row>
        </div>
        <div v-for="ageGroup of limit.ageGroups" :key="ageGroup.id">
          <div
            @click="selectLimit(limit, ageGroup)"
            class="select-line"
            :class="isSelected(limit, ageGroup) ? 'selected-line' : ''"
          >
            <b-row v-if="hasLimit(ageGroup)">
              <b-col cols="8" class="limit-name">
                <span class="badge badge-dark" v-b-tooltip="getBadgeTooltip(limit, ageGroup)">
                  {{ getBadge(limit, ageGroup) }}
                </span>
                {{ getName(limit, ageGroup) }}
              </b-col>
              <b-col class="text-right">
                <span v-for="moment of limitMoments(limit)" :key="moment">
                  <div
                    class="limit-value"
                    :style="getStyle(limit, moment, ageGroup)"
                    v-b-tooltip="getTooltip(limit, moment, ageGroup)"
                  >
                    {{ getText(limit, moment, ageGroup) }}
                  </div>
                </span>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="limits-line">
        <div
            @click="selectLimit()"
            class="select-line"
            :class="isSelected() ? 'selected-line' : ''"
          >
            <b-row>
              <b-col cols="8" class="limit-name">
                <span class="badge badge-dark" v-b-tooltip="getBadgeTooltip(null, null)">
                  {{ getBadge(null, null) }}
                </span>
                {{ seance.name }}
              </b-col>
              <b-col class="text-right">
                <span v-for="moment of limitMoments(null)" :key="moment">
                  <div class="limit-value">
                    {{ getText(null, moment, null) }}
                  </div>
                </span>
              </b-col>
            </b-row>
          </div>
      </div>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

import { mapActions, mapMutations } from 'vuex'
import { BackendMixin } from '@/mixins/backend'

export default {
  name: 'SeanceInscriptionLimitsIndicator',
  components: {
  },
  mixins: [BackendMixin],
  props: {
    seance: Object,
    seanceInscriptionLimits: [Object, null],
    selection: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
    }
  },
  watch: {
    seance: function(seance) {},
    selection: function() {},
  },
  computed: {
  },
  methods: {
    ...mapActions(['addError']),
    ...mapMutations(['startLoading', 'endLoading']),
    hasLimit(elt) {
      return elt.morningMax || elt.lunchMax || elt.afternoonMax
    },
    limitMoments(limit) {
      if (limit && limit.seanceId) {
        return ['morning']
      }
      return ['morning', 'lunch', 'afternoon']
    },
    getBadge(limit, ageGroup) {
      if (limit) {
        if (limit.seanceId) {
          if (limit.isExcursion()) {
            return ageGroup ? 'ExtA' : 'ExtG'
          } else {
            return ageGroup ? 'SA' : 'SG'
          }
        } else {
          return ageGroup ? 'JA' : 'JG'
        }
      }
      return '--'
    },
    getBadgeTooltip(limit, ageGroup) {
      if (limit) {
        if (limit.seanceId) {
          if (limit.isExcursion()) {
            return ageGroup ? 'Limite par (sortie ou séjour) et par âge' : 'Limite globale pour la sortie ou le séjour'
          } else {
            return ageGroup ? 'Limite par séance et par âge' : 'Limite globale pour la séance'
          }
        } else {
          return ageGroup ? 'Limite par journée et par âge' : 'Limite globale pour la journée'
        }
      }
      return 'Pas de limite'
    },
    getName(limit, group) {
      let name = this.seance.name
      if (group) {
        name += ' ' + group.groupName
      }
      return name
    },
    getMaxValue(limit, moment, ageGroup) {
      let maxValue
      if (ageGroup) {
        maxValue = ageGroup[moment + 'Max'] || 0
      } else {
        maxValue = limit[moment + 'Max'] || 0
      }
      return maxValue
    },
    getValue(limit, moment, ageGroup) {
      if (this.seanceInscriptionLimits) {
        let counter = this.seanceInscriptionLimits.dailyCounter
        if (limit && limit.seanceId) {
          if (limit.isExcursion()) {
            counter = this.seanceInscriptionLimits.excursionCounter
          } else {
            counter = this.seanceInscriptionLimits.seanceCounter
          }
        }
        const ageGroupId = ageGroup ? ageGroup.groupId : 0
        const ageGroupCounter = counter.filter(elt => elt.ageGroupId === ageGroupId)
        if (ageGroupCounter.length) {
          return ageGroupCounter[0][moment] || 0
        }
        return counter[moment] || 0
      }
      return 0
    },
    getText(limit, moment, ageGroup) {
      if (limit) {
        return '' + (this.getValue(limit, moment, ageGroup))
      } else {
        if (this.seance[moment]) {
          return '' + (this.getValue(limit, moment, ageGroup))
        } else {
          return '-'
        }
      }
    },
    getTooltip(limit, moment, ageGroup) {
      const maxValue = this.getMaxValue(limit, moment, ageGroup)
      if (maxValue) {
        return '' + (this.getValue(limit, moment, ageGroup)) + '/' + maxValue
      }
      return ''
    },
    getStyle(limit, moment, ageGroup) {
      if (limit) {
        const value = this.getValue(limit, moment, ageGroup)
        const maxValue = this.getMaxValue(limit, moment, ageGroup)
        if (maxValue) {
          if (value > maxValue) {
            return {
              backgroundColor: '#e96eff',
              color: '#000',
            }
          } else if (value === maxValue) {
            return {
              backgroundColor: '#ff6e6e',
              color: '#000',
            }
          } else if (value >= (maxValue - 5)) {
            return {
              backgroundColor: '#ebe770',
              color: '#000',
            }
          } else {
            return {
              backgroundColor: '#e1ffdd',
              color: '#000',
            }
          }
        }
      }
      return {
        backgroundColor: '#fff',
        color: '#000',
      }
    },
    selectLimit(limit, ageGroup) {
      this.$emit(
        'selected',
        {
          limit: limit,
          ageGroup: ageGroup,
          name: this.getName(limit, ageGroup),
          seanceDate: this.seance.date,
          seanceId: this.seance.id,
        }
      )
    },
    isSelected(limit = null, ageGroup = null) {
      if (this.selection) {
        if (limit !== null) {
          if (!this.selection.limit) {
            return false
          }
          if ((this.selection.seanceDate === this.seance.date) && (this.selection.limit.id === limit.id)) {
            if ((this.selection.ageGroup === null) && (ageGroup === null)) {
              return true
            } else if ((this.selection.ageGroup !== null) && (ageGroup !== null)) {
              return (this.selection.ageGroup.groupId === ageGroup.groupId)
            } else {
              return false
            }
          } else {
            return false
          }
        } else {
          return this.selection.seanceId === this.seance.id
        }
      } else {
        return false
      }
    },
  },
  mounted() {
  },
}
</script>
<style lang="less">
.limit-name {
  display: inline-block;
  width: 370px;
}
.limit-value {
  display: inline-block;
  border: solid 1px #444;
  padding: 2px 2px;
  min-width: 28px;
  font-size: 11px;
  margin: 1px 1px;
  text-align: center;
  border-radius: 4px;
}
@media (max-width: 1350px) {
  .limit-value {
    padding: 3px 2px;
    min-width: 20px;
    font-size: 9px;
    margin: 1px 1px;
    text-align: center;
    border-radius: 4px;
  }
}
.limits-line, .sub-limits-line {
  border-bottom: solid 1px #ccc;
}
.sub-limits-line:last-of-type {
  border: none;
}
.select-line {
  cursor: pointer;
}
.selected-line {
  font-weight: bold;
  background: #ccc;
}
</style>
