<template>
  <div>
    <div
      v-if="seanceInscriptionLimits && seanceInscriptionLimits.limits && seanceInscriptionLimits.limits.length"
    >
      <div v-for="limit of seanceInscriptionLimits.limits" :key="limit.id">
        <div v-if="hasLimit(limit)">
          <span class="badge badge-dark" v-b-tooltip="getBadgeTooltip(limit)">
            {{ getBadge(limit, null) }}
          </span>
          <span v-for="moment of limitMoments(limit)" :key="moment">
            <div
              class="limit-value"
              :style="getStyle(limit, moment, null)"
              v-b-tooltip="getTooltip(limit, moment, null)"
            >
              {{ getText(limit, moment, null) }}
            </div>
          </span>
        </div>
        <div v-for="ageGroup of limit.ageGroups" :key="ageGroup.id">
          <div v-if="hasLimit(ageGroup) && inscription.ageGroup.id === ageGroup.groupId">
            <span class="badge badge-dark" v-b-tooltip="getBadgeTooltip(limit, ageGroup)">
              {{ getBadge(limit, ageGroup) }}
            </span>
            <span v-for="moment of limitMoments(limit)" :key="moment">
              <div
                class="limit-value"
                :style="getStyle(limit, moment, ageGroup)"
                v-b-tooltip="getTooltip(limit, moment, ageGroup)"
              >
                {{ getText(limit, moment, ageGroup) }}
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

import { mapActions, mapMutations } from 'vuex'
import { BackendMixin } from '@/mixins/backend'

export default {
  name: 'SingleInscriptionLimitsIndicator',
  components: {
  },
  mixins: [BackendMixin],
  props: {
    inscription: Object,
    seanceInscriptionLimits: [Object, null],
  },
  data() {
    return {
    }
  },
  watch: {
    inscription: function() {},
    seanceInscriptionLimits: function() {},
  },
  computed: {
    seance() {
      return this.inscription.seance
    },
  },
  methods: {
    ...mapActions(['addError']),
    ...mapMutations(['startLoading', 'endLoading']),
    limitMoments(limit) {
      if (limit && limit.seanceId) {
        return ['morning']
      }
      return ['morning', 'lunch', 'afternoon']
    },
    hasLimit(elt) {
      return elt.morningMax || elt.lunchMax || elt.afternoonMax
    },
    getMaxValue(limit, moment, ageGroup) {
      let maxValue
      if (ageGroup) {
        maxValue = ageGroup[moment + 'Max'] || 0
      } else {
        maxValue = limit[moment + 'Max'] || 0
      }
      return maxValue
    },
    getValue(limit, moment, ageGroup) {
      if (this.seanceInscriptionLimits) {
        let counter = this.seanceInscriptionLimits.dailyCounter
        if (limit && limit.seanceId) {
          if (limit.isExcursion()) {
            counter = this.seanceInscriptionLimits.excursionCounter
          } else {
            counter = this.seanceInscriptionLimits.seanceCounter
          }
        }
        const ageGroupId = ageGroup ? ageGroup.groupId : 0
        const ageGroupCounter = counter.filter(elt => elt.ageGroupId === ageGroupId)
        if (ageGroupCounter.length) {
          return ageGroupCounter[0][moment] || 0
        }
        return counter[moment] || 0
      }
      return 0
    },
    getText(limit, moment, ageGroup) {
      if (this.seance[moment]) {
        return '' + (this.getValue(limit, moment, ageGroup))
      } else {
        return '-'
      }
    },
    getBadge(limit, ageGroup) {
      if (limit) {
        if (limit.seanceId) {
          if (limit.isExcursion()) {
            return ageGroup ? 'ExtA' : 'ExtG'
          } else {
            return ageGroup ? 'SA' : 'SG'
          }
        } else {
          return ageGroup ? 'JA' : 'JG'
        }
      }
      return '--'
    },
    getBadgeTooltip(limit, ageGroup) {
      if (limit.seanceId) {
        if (limit.isExcursion()) {
          return ageGroup ? 'Limite par (sortie ou séjour) et par âge' : 'Limite globale pour la sortie ou le séjour'
        } else {
          return ageGroup ? 'Limite par séance et par âge' : 'Limite globale pour la séance'
        }
      } else {
        return ageGroup ? 'Limite par journée et par âge' : 'Limite globale pour la journée'
      }
    },
    getTooltip(limit, moment, ageGroup) {
      const maxValue = this.getMaxValue(limit, moment, ageGroup)
      if (maxValue) {
        return '' + (this.getValue(limit, moment, ageGroup)) + '/' + maxValue
      }
      return ''
    },
    getStyle(limit, moment, ageGroup) {
      if (limit) {
        const value = this.getValue(limit, moment, ageGroup)
        const maxValue = this.getMaxValue(limit, moment, ageGroup)
        if (maxValue) {
          if (value > maxValue) {
            return {
              backgroundColor: '#e96eff',
              color: '#000',
            }
          } else if (value === maxValue) {
            return {
              backgroundColor: '#ff6e6e',
              color: '#000',
            }
          } else if (value >= (maxValue - 5)) {
            return {
              backgroundColor: '#ebe770',
              color: '#000',
            }
          } else {
            return {
              backgroundColor: '#e1ffdd',
              color: '#000',
            }
          }
        }
      }
      return {
        backgroundColor: '#fff',
        color: '#000',
      }
    },
  },
  mounted() {
  },
}
</script>
<style lang="less">
.limit-name {
  display: inline-block;
  width: 370px;
}
.limit-value {
  display: inline-block;
  border: solid 1px #444;
  padding: 2px 2px;
  min-width: 28px;
  font-size: 11px;
  margin: 1px 1px;
  text-align: center;
  border-radius: 4px;
}
@media (max-width: 1350px) {
  .limit-value {
    padding: 3px 2px;
    min-width: 24px;
    font-size: 9px;
    margin: 1px 1px;
    text-align: center;
    border-radius: 4px;
  }
}
</style>
